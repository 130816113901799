'use client';
import { FiMoon, FiSun } from 'react-icons/fi';
import { Button } from '@reshima/pure-ui';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { useTheme } from './context';

export function ThemeToggler() {
  const name = 'ThemeToggler';
  const { theme, setTheme } = useTheme();

  function onThemeClick() {
    const action = Action.Click;
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    const properties = { oldTheme: theme, newTheme };

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
      properties,
    });

    try {
      if (!setTheme) {
        throw new Error('setTheme is not defined');
      }

      setTheme(newTheme);

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
      });
    } catch (error) {
      trackException({
        name,
        action,
        error,
        properties,
        start,
      });
    }
  }

  return (
    <Button
      onClick={onThemeClick}
      className="text-2xl p-2"
      aria-label="Theme Toggler"
      ghost
      circle
    >
      <FiSun className="hidden dark:block" />
      <FiMoon className="dark:hidden" />
    </Button>
  );
}
