'use client';
import { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { ThemeProvider } from '@reshima/theme';
import { ModalProvider } from '@reshima/modals-ui';
import { ClientAuthProvider } from '@reshima/client-auth-ui';
import { Dictionary } from '@reshima/translations';
import { TranslationsProvider } from '@reshima/translations-ui';
import { NavBar } from '@reshima/nav-bar-ui';
import { Footer } from './components/footer';

const Initializer = dynamic(async () => {
  await (await import('./initializations/initializer')).default();
  return ({ children }: PropsWithChildren) => children;
});

export function App({
  children,
  dictionary,
}: PropsWithChildren<{ dictionary: Dictionary }>) {
  return (
    <Initializer>
      <TranslationsProvider dictionary={dictionary}>
        <ClientAuthProvider>
          <ThemeProvider>
            <ModalProvider>
              <NavBar />
              <main className="w-full flex-1 flex justify-center p-2 xs:px-3">
                {children}
              </main>
              <Footer />
            </ModalProvider>
          </ThemeProvider>
        </ClientAuthProvider>
      </TranslationsProvider>
    </Initializer>
  );
}
